import React, { useState } from "react"
import { Button } from "antd"
import "./style.scss"
import { Link } from "gatsby"
import Flea from "../../../assets/layout/widget/pests/flea.svg"
import CockRoach from "../../../assets/layout/widget/pests/cockroach.svg"
import BedBug from "../../../assets/layout/widget/pests/bed-bug.svg"
import Spider from "../../../assets/layout/widget/pests/spider.svg"
import Termite from "../../../assets/layout/widget/pests/termite.svg"
import Rodent from "../../../assets/layout/widget/pests/rodent.svg"
import AntPest from "../../../assets/layout/widget/pests/ant-pest.svg"

import Home from "../../../assets/layout/widget/cardFooter/home.svg"
import Pest from "../../../assets/layout/widget/cardFooter/pest.svg"

import Sydney from "../../../assets/layout/widget/cardFooter/sydney.svg"
import Crew from "../../../assets/layout/widget/cardFooter/crew.svg"
import Ten from "../../../assets/layout/widget/cardFooter/ten.svg"
import Shield from "../../../assets/layout/widget/cardFooter/shield.svg"

import { CloseCircleOutlined } from "@ant-design/icons"

const PestWidget = () => {
  return <PestWidgetInner />
}

const PestWidgetInner = () => {
  const [show, setShow] = useState("none")
  const [name, setName] = useState("")
  const [link, setLink] = useState("")
  const handleClick = () => {
    if (show === "block") {
      setShow("none")
    } else {
      setShow("block")
    }
  }

  const handleFlea = () => {
    setShow("block")
    setName("The End of Lease")
    setLink("/the-end-of-lease")
  }
  const handleCockroach = () => {
    setShow("block")
    setName("The German")
    setLink("/the-german")
  }
  const handleBedBug = () => {
    setShow("block")
    setName("The BedBug")
    setLink("/the-bed-bug")
  }
  const handleSpider = () => {
    setShow("block")
    setName("The Spider")
    setLink("/the-spider")
  }
  const handleTermite = () => {
    setShow("block")
    setName("The Termite")
    setLink("/termite-inspection-sydney")
  }
  const handleRodent = () => {
    setShow("block")
    setName("The Rodent")
    setLink("/the-rodent")
  }
  const handleAntPest = () => {
    setShow("block")
    setName("The AntPest")
    setLink("/the-ant")
  }
  return (
    <div className="fixed-widget-layout">
      <div className="pest-widget-general" style={{ display: show }}>
        <Button className="close-widget" onClick={handleClick}>
          <CloseCircleOutlined />
        </Button>
        <div className="pest-widget-general-inner">
          <h3>{name}</h3>
          <p className="widget-info">
            <img src={Home} alt="" /> Flat rate $170 any house
          </p>
          <p className="widget-info">
            <img src={Pest} alt="" /> Includes rodent treatment
          </p>
          <p className="widget-info">
            <img src={Sydney} alt="" /> Servicing all of Sydney
          </p>
          <p className="widget-info">
            <img src={Crew} alt="" /> Servicing all of Sydney
          </p>
          <p className="widget-info">
            <img src={Ten} alt="" /> 10 Years experience
          </p>
          <p className="widget-info">
            <img src={Shield} alt="" /> All pests {"&"} bugs treated
          </p>
        </div>

        <div className="btn-container-widget" onClick={handleClick}>
          <Link to={link}>VIEW INFO</Link>
        </div>
      </div>
      <div>
        <div className="pest-widget-holder ">
          <img src={Flea} alt="Flea" />
          <Link to="/the-end-of-lease">
            <Button
              className="pest-widget-price pest-widget-animation1"
            // onClick={handleFlea}
            >
              <img src={Flea} alt="Flea" />
              <div>
                <span>the end of lease</span>
                <p>$170</p>
              </div>
            </Button>
          </Link>
        </div>

        <div className="pest-widget-holder">
          <img src={CockRoach} alt="CockRoach" />
          <Link to="/cockroach-pest-control-sydney/german-cockroaches">
            <Button
              className="pest-widget-price pest-widget-animation2"
            // onClick={handleCockroach}
            >
              <img src={CockRoach} alt="CockRoach" />
              <div>
                <span>the german</span>
                <p>$170</p>
              </div>
            </Button>
          </Link>
        </div>

        <div className="pest-widget-holder">
          <img src={BedBug} alt="bed bug" />
          <Link to="/bed-bug-control">
            <Button
              className="pest-widget-price pest-widget-animation3"
            // onClick={handleBedBug}
            >
              <img src={BedBug} alt="bed bug" />
              <div>
                <span>the BedBug</span>
                <p>$340</p>
              </div>
            </Button>
          </Link>
        </div>

        <div className="pest-widget-holder">
          <img src={Spider} alt="spider" />
          <Link to="/spider-pest-control-sydney">
            <Button
              className="pest-widget-price pest-widget-animation4"
            // onClick={handleSpider}
            >
              <img src={Spider} alt="spider" />
              <div>
                <span>the spider</span>
                <p>$150</p>
              </div>
            </Button>
          </Link>
        </div>

        <div className="pest-widget-holder">
          <img src={Termite} alt="termite" />
          <Link to="/termite-inspection-sydney">
            <Button
              className="pest-widget-price pest-widget-animation5"
            // onClick={handleTermite}
            >
              <img src={Termite} alt="termite" />
              <div>
                <span>the termite</span>
                <p>$220</p>
              </div>
            </Button>
          </Link>
        </div>

        <div className="pest-widget-holder">
          <img src={Rodent} alt="rodent" />
          <Link to="/rodent-control-sydney">
            <Button
              className="pest-widget-price pest-widget-animation6"
            // onClick={handleRodent}
            >
              <img src={Rodent} alt="rodent" />
              <div>
                <span>the rodent</span>
                <p>$290</p>
              </div>
            </Button>
          </Link>
        </div>

        <div className="pest-widget-holder">
          <img src={AntPest} alt="ant pest" />
          <Link to="/ant-pest-control-sydney">
            <Button
              className="pest-widget-price pest-widget-animation7"
            // onClick={handleAntPest}
            >
              <img src={AntPest} alt="ant pest" />
              <div>
                <span>the ant</span>
                <p>$190</p>
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PestWidget
