import React from "react"

import ellipse from "../../../assets/home/icons/ellipse.svg"
import call from "../../../assets/home/icons/call.svg"
import quotes from "../../../assets/home/icons/quotes.svg"
import Home from "../../../assets/home/icons/home.svg"
import Pest from "../../../assets/home/icons/pest.svg"
import Sydney from "../../../assets/home/icons/sydney.svg"
import Crew from "../../../assets/home/icons/crew.svg"
import Ten from "../../../assets/home/icons/ten.svg"
import Shield from "../../../assets/home/icons/shield.svg"
import callSmall from "../../../assets/home/icons/hero-call.svg"
import { useLocation } from "@reach/router"

import "./style.scss"

const stripSlashes = path => {
  return path.split("/").join("")
}

export default function GeneralPest({ offerIncludes, offerFeatures }) {
  const location = useLocation()
  const [currentPath, setCurrenpath] = React.useState("")
  const [title, setTitle] = React.useState("")
  const [price, setPrice] = React.useState("")

  React.useEffect(() => {
    setCurrenpath(stripSlashes(location.pathname))
    switch (currentPath) {
      case "the-end-of-lease":
        setTitle("THE END OF LEASE")
        setPrice("170")
        break
      case "the-german":
      case "cockroach-pest-control-sydneygerman-cockroaches":
        setTitle("THE GERMAN")
        setPrice("170")
        break
      case "the-bed-bug":
      case "bed-bug-control":
        setTitle("THE BED BUG")
        setPrice("340")
        break
      case "the-spider":
      case "spider-pest-control-sydney":
        setTitle("THE SPIDER")
        setPrice("150")
        break
      case "termite-inspection-sydney":
        setTitle("THE TERMITE INSPECTION")
        setPrice("220")
        break
      case "the-rodent":
      case "rodent-control-sydney":
        setTitle("THE RODENT")
        setPrice("290")
        break
      case "the-ant":
      case "ant-pest-control-sydney":
        setTitle("THE ANT")
        setPrice("190")
        break

      case "commercial-pest-control":
        setTitle("Commercial Pest Control Sydney")
        setPrice("0")
        break
      case "commercial-pest-controlstrata-pest-control":
        setTitle("Strata Pest Control Sydney")
        setPrice("0")
        break
      case "commercial-pest-controlschool-pest-control":
        setTitle("School Pest Control Sydney")
        setPrice("0")
        break
      case "commercial-pest-controltransport-pest-control":
        setTitle("Transport Pest Control Sydney")
        setPrice("0")
        break
      case "commercial-pest-controlgovernment-pest-control":
        setTitle("Government Pest Control Sydney")
        setPrice("0")
        break
      case "commercial-pest-controlpest-inspection":
        setTitle("Pest Inspection Sydney")
        setPrice("0")
        break
      case "commercial-pest-controlaged-care-pest-control":
        setTitle("Aged Care Pest Control Sydney")
        setPrice("0")
        break
      case "residential-pest-control":
        setTitle("Residential Pest Control Sydney")
        setPrice("0")
        break
      case "pet-and-kid-friendly-pest-control":
        setTitle("Kid and Pet Friendly Pest Control Sydney")
        setPrice("0")
        break
      case "organic-pest-control":
        setTitle("Organic Pest Control Sydney")
        setPrice("0")
        break
      case "termite-treatment":
        setTitle("Termite Treatment Sydney")
        setPrice("0")
        break
      case "pest-typesthe-termite":
        // setTitle("Termite Inspection Sydney")
        // setPrice("0")
        setTitle("THE TERMITE INSPECTION")
        setPrice("189")
        break
      case "termite-prevention":
        setTitle("Termite Prevention Sydney")
        setPrice("0")
        break
      case "cockroach-pest-control-sydney":
        setTitle("Cockroach Pest Control Sydney")
        setPrice("0")
        break
      case "spider-pest-controlredback-spider-pest-control-sydney":
        setTitle("Redback Spider Pest Control Sydney")
        setPrice("0")
        break
      case "spider-pest-controlfunnel-web-spider-pest-control":
        setTitle("Funnel-Web Spider Pest Control Sydney")
        setPrice("0")
        break
      case "ant-pest-control-sydneywhite-ant-pest-control":
        setTitle("White Ant Pest Control Sydney")
        setPrice("0")
        break
      case "bird-pest-control-sydney":
        setTitle("Bird Pest Control Sydney")
        setPrice("0")
        break
      case "bird-pest-controlpigeon-pest-control":
        setTitle("Pigeon Pest Control Sydney")
        setPrice("0")
        break
      case "feral-animal-removal":
        setTitle("Feral Animal Removal Sydney")
        setPrice("0")
        break
      case "feral-animal-removalpossum-pest-control":
        setTitle("Possum Pest Control Sydney")
        setPrice("0")
        break
      case "feral-animal-removalrabbit-control":
        setTitle("Rabbit Pest Control Sydney")
        setPrice("0")
        break
      case "feral-animal-removalferal-cat-control":
        setTitle("Feral Cat Pest Control Sydney")
        setPrice("0")
        break
      case "fly-control":
        setTitle("Fly Control Sydney")
        setPrice("0")
        break
      case "flying-insect-control":
        setTitle("Flying Insect Control Sydney")
        setPrice("0")
        break
      case "fly-controlmosquito-control-sydney":
        setTitle("Mosquito Pest Control Sydney")
        setPrice("0")
        break
      case "fly-controlbee-wasp-control-sydney":
        setTitle("Bee & Wasp Control Sydney")
        setPrice("0")
        break
      case "silverfish-control":
        setTitle("Silverfish Pest Control Sydney")
        setPrice("0")
        break
      case "bed-bug-control":
        setTitle("Bed Bug Pest Control Sydney")
        setPrice("0")
        break
      case "bird-mites-control":
        setTitle("Bird Mites Control Sydney")
        setPrice("0")
        break
      case "carpet-beetle-control":
        setTitle("Carpet Beetle Pest Control Sydney")
        setPrice("0")
        break
      case "moth-control":
        setTitle("Moth Pest Control Sydney")
        setPrice("0")
        break
      case "moth-controlpantry-moth-control":
        setTitle("Pantry Moth Pest Control Sydney")
        setPrice("0")
        break

      default:
        setTitle("THE GENERAL PEST")
        setPrice("220")
    }
  }, [currentPath])

  const defaultIncludes = (
    <>
      {" "}
      <span className="place">ANY HOUSE</span>
      <img src={ellipse} alt="" />
      <span className="place">INSIDE AND OUT</span>
      <img src={ellipse} alt="" />
      <span className="place">ALL PESTS TREATED</span>
    </>
  )

  const defaultFeatures = (
    <>
      <p>
        <img src={Home} alt="" /> FLAT RATE ${price} ANY HOUSE
      </p>
      <p>
        <img src={Pest} alt="" /> INCLUDES RODENT TREATMENT
      </p>
      <p>
        <img src={Sydney} alt="" /> SERVICING ALL OF SYDNEY
      </p>
      <p>
        <img src={Crew} alt="" /> FULLY LICENSED CREW
      </p>
      <p>
        <img src={Ten} alt="" /> 10 YEARS EXPERIENCE
      </p>
      <p>
        <img src={Shield} alt="" /> INCLUDES 6 MTH WARRANTY
      </p>
    </>
  )

  return (
    <div className="features">
      <div className="features-general">
        <h4>{title}</h4>
        {price && price !== "0" ? <h2>${price}</h2> : null}
        {offerIncludes ? (
          offerIncludes.length === 1 ? (
            <span className="place">
              {offerIncludes[0]
                ? offerIncludes[0].includes[0]
                  ? offerIncludes[0].includes[0].text
                    ? offerIncludes[0].includes[0].text
                    : ""
                  : ""
                : ""}
            </span>
          ) : null
        ) : null}
        {offerIncludes ? (
          offerIncludes[0].includes[0] ? (
            offerIncludes[0].includes[0].text ? (
              offerIncludes.length !== 1 ? (
                offerIncludes.length === 3 ? (
                  <>
                    <span className="place">
                      {offerIncludes[0]
                        ? offerIncludes[0].includes[0]
                          ? offerIncludes[0].includes[0].text
                            ? offerIncludes[0].includes[0].text
                            : ""
                          : ""
                        : ""}
                    </span>
                    <img src={ellipse} alt="" />
                    <span className="place">
                      {offerIncludes[1]
                        ? offerIncludes[1].includes[0]
                          ? offerIncludes[1].includes[0].text
                            ? offerIncludes[1].includes[0].text
                            : ""
                          : ""
                        : ""}
                    </span>
                    <img src={ellipse} alt="" />
                    <span className="place">
                      {offerIncludes[2]
                        ? offerIncludes[2].includes[0]
                          ? offerIncludes[2].includes[0].text
                            ? offerIncludes[2].includes[0].text
                            : ""
                          : ""
                        : ""}
                    </span>
                  </>
                ) : (
                  defaultIncludes
                )
              ) : null
            ) : (
              defaultIncludes
            )
          ) : (
            defaultIncludes
          )
        ) : (
          defaultIncludes
        )}

        <div className="get-quote">
          <a href="tel:1300 007 772">
            <div className="call">
              <img src={call} alt="" />
              <img src={callSmall} alt="" />
              <p className="free-quote">
                FREE QUOTE! CALL NOW
                <br></br>
                <span>1300 007 772</span>
              </p>
            </div>
          </a>
        </div>
        <div className="quote-conatiner">
          <img src={quotes} alt="" className="quote-img" />
          <p className="quote">
            Very happy with an on-time service. Would recommend to friends and
            family! <br /> <br />
            <span>Karla</span>, Greenwich
          </p>
        </div>
        <div className="quote-conatiner">
          <img src={quotes} alt="" className="quote-img" />
          <p className="quote">
            Service was great and Jeff was helpful. Have already recommended him
            to my son. <br /> <br />
            <span>May {"&"} Dennis</span>, Padstow
          </p>
        </div>
        <div className="quote-conatiner">
          <img src={quotes} alt="" className="quote-img" />
          <p className="quote">
            Had all 3 of my properties done - will definitely call them back
            next year. <br /> <br />
            <span>David</span>, Neutral Bay
          </p>
        </div>
      </div>
      <div className="features-cost">
        <h6 className="title">
          Sydney's <span>LOW COST </span>professional pest control company.
          {price && price !== "0"
            ? ` Price fixed at $${price} for any house!`
            : null}
        </h6>
        {/* <p>
          <img
            src={
              features[0]
                ? features[0].image
                  ? features[0].image
                  : null
                : null
            }
            alt=""
          />{" "}
          {features[0] ? (features[0].text ? features[0].text : "...") : "..."}
        </p> */}
        {offerFeatures
          ? offerFeatures[0].features[0]
            ? offerFeatures[0].features[0].text
              ? offerFeatures.map(offerFeature => (
                <p>
                  <img
                    src={
                      offerFeature.features[1]
                        ? offerFeature.features[1].url
                          ? offerFeature.features[1].url
                          : null
                        : null
                    }
                    alt=""
                  />{" "}
                  {offerFeature.features[0]
                    ? offerFeature.features[0].text
                      ? offerFeature.features[0].text
                      : "..."
                    : "..."}
                </p>
              ))
              : defaultFeatures
            : defaultFeatures
          : defaultFeatures}
        <p
          className="conditionsApply"
          style={{
            fontSize: "14px",
            textAlign: "center",
            display: "block",
            padding: 0,
          }}
        >
          ***Conditions Apply***
        </p>
      </div>
    </div>
  )
}
