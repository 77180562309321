import React from "react"
import LiteYouTubeEmbed from "react-lite-youtube-embed"
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

export default function LazyIframe({ url, title }) {
  const formattedUrl = url ? url.replace("watch?v=", "embed/") : ""
  const videoCode = url ? url.split("?v=")[1] : ""

  return (
    <div className="video-background">
      <iframe
        src={`https://www.youtube.com/embed/biWk-QLWY7U?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&playlist=${videoCode}`}
        frameborder="0"
        allowfullscreen
      ></iframe>

      {/* <iframe
        title={title}
        style={{
          bottom: 0,
          height: "100%",
          left: 0,
          position: "absolute",
          right: 0,
          top: 0,
          width: "100%",
        }}
        src={
          formattedUrl +
          `?autoplay=1&mute=1&controls=0&modestbranding=1&loop=1&rel=0&fs=0&iv_load_policy=3&playlist=${videoCode}&enablejsapi=1`
        }
        frameborder="0"
      ></iframe> */}

      {/* <LiteYouTubeEmbed
        id={videoCode}
        title={title}
        params={`autoplay=1&mute=1&controls=0&modestbranding=1&loop=1&rel=0&fs=0&iv_load_policy=3&playlist=${videoCode}&enablejsapi=1`}
        // playlist={true}
        // playlistCoverId={videoCode}
        // params="autoplay=1&mute=1&controls=0&modestbranding=1&loop=1"
      /> */}
    </div>
  )
}
