import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import LazyIframe from "./LazyVideo"

export default function BannerCarousel({
  bannerVideo,
  bannerImage1,
  bannerImage2,
}) {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 15000,
    cssEase: "ease-in-out",
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
  }

  // React.useEffect(() => {
  //   document.getElementById("autoplay").play()
  // }, [])

  // const videoData = bannerVideo ? bannerVideo : null

  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    if (window !== "undefined") {
      setScreenWidth(window.innerWidth)
    }
  }, [])

  useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener("resize", function () {
        setScreenWidth(this.window.innerWidth)
      })
    }
  })

  return (
    <section className="banner-carousel_section">
      {/* Carousel */}
      <div className="banner-carousel_container">
        <Slider {...settings}>
          {/* <video
            style="width: auto; display: inline-block;"
            id="autoplay"
            poster={data ? data.placeholder_image.url : null}
            autoplay
            muted
            loop
          >
            <source
              src={data ? data.banner_video.url : null}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video> */}
          {screenWidth > 580 ? (
            <LazyIframe
              // url="https://www.youtube.com/embed/6OM3zoAJ3lU?autoplay=1&mute=1&controls=0&modestbranding=1&loop=1&rel=0&fs=0&iv_load_policy=3&playlist=6OM3zoAJ3lU"
              url={
                bannerVideo
                  ? bannerVideo.embed_url
                    ? bannerVideo.embed_url
                    : null
                  : null
              }
              title={
                bannerVideo
                  ? bannerVideo.title
                    ? bannerVideo.title
                    : null
                  : null
              }
            />
          ) : null}

          {bannerImage1 ? (
            <div
              className={"Banner-First-Image"}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <img
                className="bannerImage houseBannerImage"
                src={bannerImage1.url ? bannerImage1.url : null}
                alt={bannerImage1.alt ? bannerImage1.alt : null}
                style={{
                  objectFit: "contain",
                  // minWidth: "800px"
                }}
              />
            </div>
          ) : null}
          {bannerImage2 ? (
            <img
              className="bannerImage"
              src={bannerImage2.url ? bannerImage2.url : null}
              alt={bannerImage2.alt ? bannerImage2.alt : null}
            />
          ) : null}
        </Slider>
      </div>
    </section>
  )
}
